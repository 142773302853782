import React from "react";
import styled from "styled-components";

import Heading from "./shared/Heading";
import { paddingLarge } from "../variables";

const PageLayout = ({ heading, ...props }) => (
  <Wrapper>
    <Heading>{heading}</Heading>
    <ContentWrapper>{props.children}</ContentWrapper>
  </Wrapper>
);

const Wrapper = styled.div`
  padding-top: ${paddingLarge};
`;

const ContentWrapper = styled.div`
  margin-top: ${paddingLarge};
`;

export default PageLayout;
