import React, { useEffect } from "react";

const Privacy = () => {
  useEffect(() => {
    window.location.assign(
      "https://chess-universe.net/privacy-policy.pdf"
    );
  });

  return <></>;
};

export default Privacy;
