const content = [
  {
    en: "Activation link has expired.",
    ru: "Ссылка активации более не действительна.",
    es: "El enlace de activación ha caducado.",
    de: "Aktivierungslink ist abgelaufen.",
    fr: "Le lien d'activation a expiré.",
    pt: "O link de ativação expirou.",
    tr: "Etkinleştirme bağlantısının süresi doldu.",
    pl: "Link aktywacyjny wygasł.",
  },
  {
    en: "Please return to the app and sign in again.",
    ru: "Пожалуйста, вернитесь в приложение и повторите вход.",
    es: "Vuelve a la aplicación e inicia sesión de nuevo.",
    de: "Bitte geh zurück in die App und melde dich erneut an.",
    fr: "Veuillez retourner sur l'application et vous inscrire de nouveau.",
    pt: "Volte ao aplicativo e acesse sua conta de novo.",
    tr: "Lütfen uygulamaya geri dön ve tekrar oturum aç.",
    pl: "Wróć do aplikacji i zaloguj się ponownie.",
  },
];

export default content;
