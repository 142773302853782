import React from "react";

import PageLayout from "./PageLayout";

const Eula = () => (
  <PageLayout heading="EULA">
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras aliquet arcu
    id magna tempus pharetra. Ut ut nunc sed enim fermentum interdum. Sed
    sagittis a ante quis porttitor. Nunc accumsan tempor mauris vel commodo.
    Maecenas nibh lectus, laoreet eu odio ut, ornare pretium mauris. Cras
    condimentum faucibus elementum. Nulla ut urna in ipsum varius rutrum. Morbi
    iaculis consequat odio et ultricies. Vestibulum ante ipsum primis in
    faucibus orci luctus et ultrices posuere cubilia Curae; Integer elementum
    sollicitudin mattis. Curabitur imperdiet nunc nulla, vel maximus est
    facilisis a. Sed sed ligula elit. Praesent bibendum, neque eu posuere
    mattis, turpis ante viverra nibh, eget rhoncus nunc nunc quis nibh. Lorem
    ipsum dolor sit amet, consectetur adipiscing elit. Aenean a suscipit ipsum.
    Morbi vel ex tempus, egestas metus sed, facilisis ex. Duis non urna sit amet
    arcu ullamcorper hendrerit. Ut tristique eget velit eu pretium. Curabitur
    magna felis, hendrerit non ligula ut, dignissim ultricies neque. Etiam
    lacinia lacus non lobortis accumsan. Nulla quis malesuada tortor. Sed
    aliquam, urna nec efficitur dapibus, ligula quam lobortis neque, eget
    consectetur lacus elit eu lorem. Nulla dapibus facilisis aliquet. Integer
    dignissim quam cursus, blandit ipsum ac, congue nunc. In malesuada dictum
    tortor. Curabitur sit amet risus velit. Quisque non pulvinar magna.
    Phasellus tristique odio non lobortis dignissim. Donec ut felis sit amet
    nunc dapibus iaculis. Vivamus euismod libero erat, sed viverra ex
    scelerisque tempus. Quisque malesuada ornare aliquet. Nullam lacinia neque
    justo, a imperdiet justo placerat sed. Cras sed fringilla metus. Sed
    sollicitudin neque non metus blandit, et cursus metus tempor. Phasellus leo
    magna, egestas et nibh sed, placerat tempus velit. Class aptent taciti
    sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.
    Integer iaculis sapien risus. Donec sollicitudin semper ultricies. Praesent
    sagittis non lorem nec tristique. Maecenas facilisis egestas dolor id
    dignissim. Ut consectetur aliquet consectetur. Duis in diam tempus,
    dignissim est sit amet, dignissim odio. Mauris sollicitudin molestie velit a
    blandit. Morbi ac purus quis odio convallis consectetur. Curabitur ultrices,
    ante ut lacinia varius, magna nisl venenatis arcu, vel pretium arcu nibh id
    odio. Pellentesque lacinia consectetur odio, vel fermentum eros auctor at.
    Proin finibus consequat est sed efficitur. Pellentesque varius tincidunt ex
    non faucibus. Pellentesque urna massa, ornare pulvinar ullamcorper eget,
    venenatis vitae velit. Aenean quis purus non mauris dictum laoreet. Praesent
    et aliquam mi. Fusce vel eros magna. Nulla ac dapibus ex.
  </PageLayout>
);

export default Eula;
