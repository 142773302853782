import React, { useEffect } from "react";

const Terms = () => {
  useEffect(() => {
    window.location.assign(
      "https://chess-universe.net/terms-of-service.pdf"
    );
  });

  return <></>;
};

export default Terms;
