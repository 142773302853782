import styled from "styled-components";
import { device } from "../../variables";

const Heading = styled.div`
  font-family: "Rockwell", sans-serif;
  color: ${({ color }) => color};
  font-size: ${({ fontSizeMobile, fontSize }) =>
    fontSizeMobile ? fontSizeMobile : fontSize ? fontSize : "30px"};
  text-transform: ${({ uppercase }) => uppercase && "uppercase"};
  text-align: ${({ center }) => center && "center"};

  @media ${device.laptop} {
    font-size: ${({ fontSize }) => (fontSize ? fontSize : "30px")};
  }
`;

export default Heading;
