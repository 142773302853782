import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import styled from "styled-components";

import AccountActivation from "./components/AccountActivation";
import Eula from "./components/Eula";
import Expired from "./components/Expired";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Navigation from "./components/Navigation";
import PasswordActivation from "./components/PasswordActivation";
import Privacy from "./components/Privacy";
import Terms from "./components/Terms";
import { colors, paddingLarge, paddingSmall, device } from "./variables";

function App() {
  return (
    <Wrapper>
      <Router>
        <Navigation></Navigation>

        <Switch>
          <Route path="/eula">
            <ContentWrapper>
              <Eula />
            </ContentWrapper>
          </Route>
          <Route path="/privacy">
            <ContentWrapper>
              <Privacy />
            </ContentWrapper>
          </Route>
          <Route path="/terms">
            <ContentWrapper>
              <Terms />
            </ContentWrapper>
          </Route>

          <Route path="/account/en">
            <AccountActivation language={"en"} />
          </Route>
          <Route path="/account/ru">
            <AccountActivation language={"ru"} />
          </Route>
          <Route path="/account/es">
            <AccountActivation language={"es"} />
          </Route>
          <Route path="/account/de">
            <AccountActivation language={"de"} />
          </Route>
          <Route path="/account/fr">
            <AccountActivation language={"fr"} />
          </Route>
          <Route path="/account/pt">
            <AccountActivation language={"pt"} />
          </Route>
          <Route path="/account/tr">
            <AccountActivation language={"tr"} />
          </Route>
          <Route path="/account/pl">
            <AccountActivation language={"pl"} />
          </Route>

          <Route path="/password-activation/en">
            <PasswordActivation language={"en"} />
            <Footer />
          </Route>
          <Route path="/password-activation/ru">
            <PasswordActivation language={"ru"} />
            <Footer />
          </Route>
          <Route path="/password-activation/es">
            <PasswordActivation language={"es"} />
            <Footer />
          </Route>
          <Route path="/password-activation/de">
            <PasswordActivation language={"de"} />
            <Footer />
          </Route>
          <Route path="/password-activation/fr">
            <PasswordActivation language={"fr"} />
            <Footer />
          </Route>
          <Route path="/password-activation/pt">
            <PasswordActivation language={"pt"} />
            <Footer />
          </Route>
          <Route path="/password-activation/tr">
            <PasswordActivation language={"tr"} />
            <Footer />
          </Route>
          <Route path="/password-activation/pl">
            <PasswordActivation language={"pl"} />
            <Footer />
          </Route>

          <Route path="/expired/en">
            <Expired language={"en"} />
            <Footer />
          </Route>
          <Route path="/expired/ru">
            <Expired language={"ru"} />
            <Footer />
          </Route>
          <Route path="/expired/es">
            <Expired language={"es"} />
            <Footer />
          </Route>
          <Route path="/expired/de">
            <Expired language={"de"} />
            <Footer />
          </Route>
          <Route path="/expired/fr">
            <Expired language={"fr"} />
            <Footer />
          </Route>
          <Route path="/expired/pt">
            <Expired language={"pt"} />
            <Footer />
          </Route>
          <Route path="/expired/tr">
            <Expired language={"tr"} />
            <Footer />
          </Route>
          <Route path="/expired/pl">
            <Expired language={"pl"} />
            <Footer />
          </Route>

          <Route path="/">
            <Home />
            <Footer />
          </Route>
        </Switch>
      </Router>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: ${colors.black};
  color: ${colors.white};
`;

const ContentWrapper = styled.div`
  padding: 0 ${paddingSmall};
  max-width: 2000px;
  margin: auto;
  @media ${device.laptop} {
    padding: 0 ${paddingLarge};
  }
`;

export default App;
