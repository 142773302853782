import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { device } from "../variables";
import Heading from "./shared/Heading";

const Footer = () => (
  <Wrapper>
    <LinksWrapper>
      <LinkBox>
        <Link to="/">Home</Link>
      </LinkBox>
      <LinkBox>
        <a href="https://chess-universe.net/privacy-policy.pdf" target="_blank" rel="noopener noreferrer">
          Privacy
        </a>
      </LinkBox>
      <LinkBox>
        <a href="https://chess-universe.net/terms-of-service.pdf" target="_blank" rel="noopener noreferrer">
          Terms
        </a>
      </LinkBox>
  <LinkBox>
        <a href="https://chess-universe.io/faqs" target="_blank" rel="noopener noreferrer">
          Chess Universe FAQ
        </a>
      </LinkBox>
    </LinksWrapper>
    <Heading fontSize="15px" center>
      © 2019 - 2024 Kings of Games. All right reserved.
    </Heading>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
  font-size: 16px;
`;

const LinksWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const LinkBox = styled.div`
  &:not(:last-child) {
    margin-right: 30px;

    @media ${device.laptop} {
      margin-right: 80px;
    }
  }
`;

export default Footer;
