import React from "react";
import styled from "styled-components";

import { paddingSmall, device, colors } from "../variables";
import content from "../content/expired";

const Expired = ({ language }) => {
  document.title = "Chess Universe | Expired";

  return (
    <ExpiredWrapper>
      <BackgroundImage src={require("../images/cover.png")}></BackgroundImage>
      <Wrapper>
        <ContentWrapper>
          <Element>{content[0][language]}</Element>
          <Element>{content[1][language]}</Element>
        </ContentWrapper>
      </Wrapper>
    </ExpiredWrapper>
  );
};

const ExpiredWrapper = styled.div`
  max-height: 1000px;
  height: calc(100vh - 100px);
`;

const BackgroundImage = styled.img`
  position: relative;
  top: -2px;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
`;

const Wrapper = styled.div`
  position: absolute;
  height: calc(100vh - 100px);
  top: 100px;
  left: 0;
  z-index: 3;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  padding: 100px ${paddingSmall};
  background-color: ${colors.black};
  text-align: center;
  width: 100%;
  @media ${device.laptop} {
    padding: 100px 0;
  }
`;

const Element = styled.div`
  font-family: "Rockwell", sans-serif;

  &:first-child {
    margin-bottom: ${paddingSmall};
    text-transform: uppercase;
  }

  &:not(:first-child) {
    color: ${colors.yellow};
  }
`;

export default Expired;
