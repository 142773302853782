const content = [
  {
    en: "Your account has been activated.",
    ru: "Ваша учётная запись активирована.",
    es: "Se ha activado tu cuenta.",
    de: "Dein Konto wurde aktiviert.",
    fr: "Votre compte a été activé.",
    pt: "Sua conta foi ativada.",
    tr: "Hesabın etkinleştirildi.",
    pl: "Twoje konto zostało aktywowane.",
  },
  {
    en: "You can return to the app and log in.",
    ru: "Вы можете вернуться в приложение и осуществить вход.",
    es: "Puedes volver a la aplicación e iniciar sesión.",
    de: "Du kannst zur App zurückkehren und dich einloggen.",
    fr: "Vous pouvez retourner sur l'application et vous connecter.",
    pt: "Volte ao aplicativo para acessar sua conta.",
    tr: "Uygulamaya geri dönüp oturum açabilirsin.",
    pl: "Możesz wrócić do aplikacji i się zalogować.",
  },
];

export default content;
