import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import Heading from "./shared/Heading";
import { paddingMedium, paddingSmall, device } from "../variables";

const Navigation = () => (
  <Wrapper>
    <Link to="/">
      <Flex>
        <Image src={require("../images/logo_kings_of_games.svg")} />
        <StyledHeading fontSize="20px" uppercase>
          Kings of games
        </StyledHeading>
      </Flex>
    </Link>
  </Wrapper>
);

const Wrapper = styled.div`
  padding: ${paddingSmall};
  background-color: black;
  position: relative;
  z-index: 4;
  height: 100px;
  display: flex;
  align-items: center;

  @media ${device.laptop} {
    padding: ${paddingMedium};
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const Image = styled.img`
  width: 50px;
  margin-right: 15px;

  @media ${device.laptop} {
    margin-right: 30px;
  }
`;

const StyledHeading = styled(Heading)`
  padding-top: 10px;
`;

export default Navigation;
