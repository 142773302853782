const content = [
  {
    en: "New password has been activated.",
    ru: "Новый пароль активирован.",
    es: "Se ha activado una nueva contraseña.",
    de: "Neues Passwort wurde aktiviert.",
    fr: "Votre nouveau mot de passe a été activé.",
    pt: "Sua nova senha foi ativada.",
    tr: "Yeni şifre etkinleştirildi.",
    pl: "Nowe hasło zostało aktywowane.",
  },
  {
    en: "You can return to the app and log in.",
    ru: "Вы можете вернуться в приложение и осуществить вход.",
    es: "Puedes volver a la aplicación e iniciar sesión.",
    de: "Du kannst zur App zurückkehren und dich einloggen.",
    fr: "Vous pouvez retourner sur l'application et vous connecter.",
    pt: "Volte ao aplicativo para acessar sua conta.",
    tr: "Uygulamaya geri dönüp oturum açabilirsin.",
    pl: "Możesz wrócić do aplikacji i się zalogować.",
  },
];

export default content;
